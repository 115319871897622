import axios from './axios.config'
import Qs from 'qs'
import {Modal, notification} from 'ant-design-vue'
import {ExclamationCircleOutlined} from '@ant-design/icons-vue'
import {createVNode} from 'vue'
import dayjs from "dayjs"

const env = process.env.NODE_ENV || 'unknow'

/**
 * 弹出对话框
 * @param title
 * @param msg
 */
const modal = (title: string, msg: string, onOk: () => void, onCancel?: (() => void)) => {
  const show = () => {
    const config: any = {
      title: () => title,
      icon: () => createVNode(ExclamationCircleOutlined),
      content: () => msg,
      onOk: () => {
        return new Promise((resolve, reject) => {
          if (onOk) {
            onOk()
          }
          resolve(true)
        })
      },
      onCancel: () => {
        return new Promise((resolve, reject) => {
          if (onCancel) {
            onCancel()
          }
          resolve(true)
        })
      }
    }
    Modal.confirm(config)
  }
  const close = () => {
    Modal.destroyAll()
  }
  return {
    show,
    close
  }
}

/**
 * 打印控制台日志
 * @param msg
 */
const log = (msg: any, ...objs: any[]) => {
  if (tools.hasDebug) {
    if (typeof msg === 'object') {
      // tslint:disable-next-line:no-console
      console.log(JSON.stringify(msg), msg, ...objs)
    } else {
      // tslint:disable-next-line:no-console
      console.log(msg, ...objs)
    }
  }
}

/**
 * 打印控制台错误日志
 * @param err
 */
const error = (err: any) => {
  if (tools.hasDebug) {
    // tslint:disable-next-line:no-console
    if (typeof console.error === 'function') {
      // tslint:disable-next-line:no-console
      console.error(err)
    } else {
      log(err)
    }
  }
}

/**
 * 打印控制台调试日志
 * @param msg
 */
const debug = (msg: any) => {
  if (tools.hasDebug) {
    // tslint:disable-next-line:no-console
    if (typeof console.error === 'function') {
      // tslint:disable-next-line:no-console
      console.debug(msg)
    } else {
      log(msg)
    }
  }
}

/**
 * 清除一个对象的所有属性
 * @param obj
 */
const clearObject = (obj: any) => {
  if (obj) {
    Object.keys(obj).map(key => {
      delete obj[key]
    })
  }
}

const date = (d: string) => {
  if (d) {
    try {
      return dayjs(d)
    } catch (err: any) {
      return null
    }
  }
}

const notify = {
  info: (msg: string, duration: number = 3) => {
    notification.info({
      message: msg,
      placement: 'bottomRight',
      duration
    })
  },
  warn: (msg: string, duration: number = 3) => {
    notification.warn({
      message: msg,
      placement: 'bottomRight',
      duration
    })
  },
  success: (msg: string, duration: number = 3) => {
    notification.success({
      message: msg,
      placement: 'bottomRight',
      duration
    })
  },
  error: (msg: string, duration: number = 3) => {
    notification.error({
      message: msg,
      placement: 'bottomRight',
      duration
    })
  },
}

/**
 * 图片压缩
 * @param file  需要压缩的文件
 * @param size  压缩的目标大小，kb, 默认500kb
 * @param device  最大压缩次数, 默认4次，次数不宜过大, 次数过大，压缩时间增长
 */
const imgCompression = (file: any, size: number = 500, device: number = 4): Promise<any> => {
  if (file[0]) {
    return Promise.all(
        Array.from(file).map((e) => imgCompression(e, size))
    ) // 如果是 file 数组返回 Promise 数组
  } else {
    return new Promise((resolve) => {
      const reader = new FileReader() // 创建 FileReader
      // @ts-ignore
      reader.onload = ({target: {result: src}}) => {
        const fileSize = Number((file.size / 1024).toFixed(2))
        // 上传图片大小未超过目标大小
        if (fileSize <= size) {
          resolve({
            file,
            origin: file,
            beforeSrc: src,
            afterSrc: src,
            beforeKB: fileSize + 'KB',
            afterKB: fileSize + 'KB',
            detail: [],
            quality: null
          })
        } else {
          // 二分法压缩
          const image = new Image() // 创建 img 元素
          image.onload = async () => {
            const canvas = document.createElement('canvas') // 创建 canvas 元素
            canvas.width = image.width
            canvas.height = image.height
            canvas.getContext('2d')?.drawImage(image, 0, 0, image.width, image.height) // 绘制 canvas
            let canvasURL: string
            let miniFile: File
            let L = true
            let quality = 0
            const detail = []
            let start = Date.now()
            for (let i = 1; i <= device; i++) {
              canvasURL = canvas.toDataURL('image/jpeg', L ? (quality += 1 / 2 ** i) : (quality -= 1 / 2 ** i))
              const buffer = atob(canvasURL.split(',')[1])
              let length = buffer.length
              const bufferArray = new Uint8Array(new ArrayBuffer(length))
              while (length--) {
                bufferArray[length] = buffer.charCodeAt(length)
              }
              miniFile = new File([bufferArray], file.name, {type: 'image/jpeg'})
              miniFile.size / 1024 > size ? (L = false) : (L = true)
              detail.push({
                quality,
                size: miniFile.size,
                kb: Number((miniFile.size / 1024).toFixed(2)),
                time: Date.now() - start
              })
              start = Date.now()
              if (L) {
                break
              }
            }
            resolve({
              detail,
              quality,
              file: miniFile!,
              origin: file,
              beforeSrc: src,
              afterSrc: canvasURL!,
              beforeKB: Number((file.size / 1024).toFixed(2)),
              afterKB: Number((miniFile!.size / 1024).toFixed(2))
            })
          }
          image.src = src
        }
      }
      reader.readAsDataURL(file)
    })
  }
}

const tools = {
  // 是否调试模式，调试模式会打印console日志
  hasDebug: true,

  methods: {
    modal,
    log,
    error,
    debug,
    imgCompression,
    clearObject,

    date,

    /**
     * 创建新ID
     * @returns {string}
     */
    newId() {
      return Date.now() + '' + Math.ceil(Math.random() * 9999)
    },

    /**
     * 判断一个对象是否是JSON对象
     * @param obj
     * @returns {boolean}
     */
    isJson(obj: any) {
      return typeof (obj) === 'object' && Object.prototype.toString.call(obj).toLowerCase() === '[object object]' && !obj.length
    },

    /**
     * 校验方法
     */
    validate(frm: any) {
      let flag = false
      frm.validate((valid: boolean) => {
        flag = valid
      })
      return flag
    },

    /**
     * 执行ajax请求
     * @param method
     * @param url
     * @param data
     * @returns {*}
     */
    async ajax(method: string, url: string, data: any = {}, contentType: string = '') {
      if (!localStorage.getItem("token")) {
        throw {
          statusCode: 401,
          code: -999900,
          message: '缺少登录信息'
        }
      }
      const options: any = {
        url,
        method,
        headers: {}
      }
      // const token = storejs.get('token')
      // if (token != null) {
      //   Object.assign(options.headers, {
      //     Authorization: token
      //   })
      // }
      switch (method) {
        case 'PUT':
        case 'POST':
        case 'PATCH':
          Object.assign(options.headers, {
            'Content-Type': contentType || 'application/json'
          })
          Object.assign(options, {
            data: (options.headers['Content-Type'] === 'application/json') ?
              JSON.stringify(data) : Qs.stringify(data, {arrayFormat: 'indices', allowDots: true})
          })
          break
        case 'DELETE': // 必须服务器配置才能接收到参数
          Object.assign(options.headers, {
            'Content-Type': contentType || 'application/x-www-form-urlencoded'
          })
          Object.assign(options, {
            data: (options.headers['Content-Type'] === 'application/json') ?
              JSON.stringify(data) : Qs.stringify(data, {arrayFormat: 'indices', allowDots: true})
          })
          break
        case 'GET':
        default:
          Object.assign(options, {
            params: data
          })
          break
      }
      // 开始执行异步AJAX请求
      let result
      await axios(options)
        .then(res => {
          if (env === 'development') {
            log('HTTP SUCCESS', res)
          }
          if (res.data.code === 1) {
            result = res.data
          } else {
            throw {
              statusCode: 200,
              code: res.data.code,
              message: res.data.msg
            }
          }
        })
        .catch(err => {
          if (env === 'development') {
            log('HTTP ERROR', err)
          }
          if (err && err.message === 'Request failed with status code 401') {
            notification.error({
              message: '异常',
              description: '没有访问权限，请先登录！',
              placement: 'bottomRight'
            })
          } else if (err && err.code === -999900) {
            sessionStorage.clear()
            localStorage.clear()
            window.location.reload()
          } else if (typeof err === 'string') {
            notification.error({
              message: '异常',
              description: err !== '' ? err : '服务或网络异常！',
              placement: 'bottomRight'
            })
          } else {
            notification.error({
              message: '异常',
              description: err.message,
              placement: 'bottomRight'
            })
          }
          throw err
        })
      return result
    },
    // 常用接口
    get(url: string, data?: any) {
      return this.ajax('GET', url, data || {})
    },
    post(url: string, data: any) {
      return this.ajax('POST', url, data || {})
    },
    put(url: string, data: any) {
      return this.ajax('PUT', url, data || {})
    },
    delete(url: string, data: any) {
      return this.ajax('DELETE', url, data || {})
    },

    // form方式访问数据
    getForm(url: string, data?: any) {
      return this.ajax('GET', url, data || {}, 'application/x-www-form-urlencoded')
    },
    postForm(url: string, data: any) {
      return this.ajax('POST', url, data || {}, 'application/x-www-form-urlencoded')
    },
    putForm(url: string, data: any) {
      return this.ajax('PUT', url, data || {}, 'application/x-www-form-urlencoded')
    },
    deleteForm(url: string, data: any) {
      return this.ajax('DELETE', url, data || {}, 'application/x-www-form-urlencoded')
    },

    // json方式访问数据
    getJson(url: string, data?: any) {
      return this.ajax('GET', url, data || {}, 'application/json')
    },
    postJson(url: string, data: any) {
      return this.ajax('POST', url, data || {}, 'application/json')
    },
    putJson(url: string, data: any) {
      return this.ajax('PUT', url, data || {}, 'application/json')
    },
    deleteJson(url: string, data: any) {
      return this.ajax('DELETE', url, data || {}, 'application/json')
    },

    // 页面处理功能

    /**
     * 页面跳转
     * @param url 页面地址
     * @param target 打开模式
     */
    jump: (url: string, target: string) => {
      if (target === '_blank') {
        window.open(url, '_blank')
      } else {
        window.location.href = url
      }
    },


    /**
     * 重置分页
     * @param page
     * @param count
     */
    initPage: (page: any, count: number) => {
      if (count) {
        page.count = count
        page.pages = Math.floor(count / page.limit) + (count % page.limit > 0 ? 1 : 0)
        if (page.current > page.pages) {
          page.current = page.pages
        }
      }
    },

    /**
     * 获取链接地址的GET参数
     * @param name 参数名称
     * @returns {*}
     */
    query: (name: string) => {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) return decodeURIComponent(r[2])
      return null
    },

    // 系统工具

    /**
     * 获取用户信息
     * @returns {*}
     */
    currentUser() {
      const u = localStorage.getItem('user')
      return u != null ? JSON.parse(u) : {}
    },
    /**
     * 获取axios
     * @returns {*}
     */
    getAxios() {
      return axios
    },

    // 下载

    /**
     * 下载Excel文件
     * @param options
     * @returns {Promise<unknown>}
     */
    exportFile(options: any = {}) {
      return new Promise<any>((resolve, reject) => {
        axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
        axios({
          method: 'get',
          url: options.url, // 请求地址
          params: options.args, // 参数
          responseType: 'blob' // 表明返回服务器返回的数据类型
        }).then(
          response => {
            if (response.data.size === 0) {
              const msg = '没有查询到数据！'
              notification.warn({
                message: '提示',
                description: msg,
                placement: 'bottomRight'
              })
              reject(msg)
              return
            }
            const blob = new Blob([response.data], {
              type: response.data.type
            })
            const fileName = decodeURIComponent(response.headers.filename)
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            // 释放内存
            window.URL.revokeObjectURL(link.href)
            resolve(response.data)
          })
          .catch(
            err => {
              reject(err)
            }
          )
      })
    },
    /**
     * 下载文件
     */
    downloadFile(fileUrl: string, fileName:string) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: fileUrl, // 请求地址
          responseType: 'blob' // 表明返回服务器返回的数据类型
        }).then(
            response => {
              if (response.data.size === 0) {
                const msg = '文件不存在！'
                notification.warn({
                  message: '提示',
                  description: msg,
                  placement: 'bottomRight'
                })
                reject(msg)
                return
              }
              const blob = new Blob([response.data], {
                type: response.data.type
              })
              const link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = fileName
              link.click()
              // 释放内存
              window.URL.revokeObjectURL(link.href)
              resolve(response.data)
            })
            .catch(
                err => {
                  reject(err)
                }
            )
      })
    },
    getQsParams (url: any) {
      const d: any[] = url.split('?')
      let obj = {}
      if (d && d.length > 1) {
        let str = d[1]
        if (str.indexOf('#') > -1) {
          str = str.substring(0, str.indexOf('#'))
        }
        obj = {
          ... obj,
          ... Qs.parse(str)
        }
      }
      return obj
    },
  }
}

export default tools

const t = tools.methods
const tip = notify
export {
  t,
  tip
}
